:root {
  --alt-color: #ccc;
}

/* BACKGROUND */
body {
  text-align: center;
  background: url('/public/img/background/sky.png') no-repeat center/cover;
  background-size: 120%; /* Spread out clouds */
  -webkit-animation: backgroundRoll 50s ease-out infinite;
  animation: backgroundRoll 50s ease-out infinite;
}

body,
#geo-button,
#celsius-fahrenheit {
  color: #fff;
  text-decoration: none; /* Remove underline celsius/fh */
}

/* Background animation */
@keyframes backgroundRoll {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* WEATHER CARD */

/* Search field */
.Search form {
  display: flex; /* Get input field and geobutton on the same line */
  font-size: 2rem;
  align-items: center;
}

.Search #search {
  color: #fff;
  width: 90%;
  border: 0;
  border-radius: 0.4rem;
  text-align: center;
  margin: 2rem 0 0 5%;
  background-color: rgba(82, 174, 196, 0.54);
}

.Search input:focus {
  outline-color: #fff;
  outline-style: double;
}
.Search input[type='search'] {
  background: url(/public/img/assets/search.svg) no-repeat;
  background-position: 2% 50%;
}

.Search input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.loader {
  position: absolute;
  left: 50%;
}

/* Location */
.Location .fa-location-dot {
  font-size: 1.3rem;
  margin-top: 1.8rem;
}

.Location#location-btn {
  background: transparent;
  border: 0;
}

.Location#geo-button {
  margin-top: 0.4em;
  text-align: center;
  position: relative;
  right: 1em;
  border: 0;
  background-color: transparent;
  opacity: 40%;
}

/* Weather card body */
.container {
  width: 55%;
  background: hsla(192, 40%, 42%, 0.5);
  border-radius: 0.4rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
  /* Vertically and horizontally center the weather card */
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Current weather section */
.CurrentWeather .current-weather-section {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* Temperature section */
.DisplayTemperature.temperature-container {
  /* Get temperature number and C/F button on the same line */
  display: flex;
  justify-content: center;
}

.DisplayTemperature #temperature {
  font-family: 'Coda';
  font-size: 4rem;
  font-weight: 400;
}

.DisplayTemperature #celsius-fahrenheit:hover,
#geo-button:hover {
  color: var(--alt-color);
}

/* City name, date and weather details */
.CurrentCity #current-city {
  font-family: 'Coda';
  font-size: 4rem;
}

/* Weather details */
.WeatherDetails.details {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-basis: 100%;
  font-size: 1.5rem;
  opacity: 60%;
}

.WeatherDetails .details,
.bottom-section,
#celsius-faiconhrenheit {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
}

/* Forecast */
.Forecast.bottom-section {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
}

.Forecast .weather-forecast-icon {
  width: 60%;
}

.Forecast .forecast-text {
  margin-bottom: 0;
}

.forecast-text > .DisplayTemperature #temperature {
  font-size: 1.5rem;
  font-family: 'Titillium Web', sans-serif;
}
/* Last updated */
.LastUpdated.date-time {
  opacity: 45%;
  margin-bottom: 2rem;
}

/* Current Day */

.CurrentTime .dayAndTime {
  font-size: 1.5rem;
  opacity: 60%;
}

/* FOOTER */

footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: center;
  background-color: rgba(82, 174, 196, 0.74);
}

footer > a {
  font-size: 0.8rem;
  font-family: monospace;
  text-decoration: none;
  color: rgba(253, 253, 253, 0.629);
}

footer > a:hover {
  color: rgba(253, 253, 253, 0.887);
}

/* MEDIA QUERIES */

@media (max-width: 300px) {
  input#search {
    font-size: 1.2rem;
  }

  .fa-location-dot {
    font-size: 0.8rem;
  }

  .container {
    width: 90%;
  }
  #current-city {
    font-size: 2rem;
  }
  body {
    background-size: 550%;
  }
  #geo-button {
    margin-top: -2.5vw;
    right: 15%;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  input#search {
    font-size: 1.2rem;
  }

  .Location .fa-location-dot {
    font-size: 1.3rem;
    position: relative;
    right: 20%;
  }
  .container {
    width: 80%;
  }
  #search-form {
    font-size: 1.5rem;
  }
  .DisplayTemperature #temperature {
    font-size: 3rem;
  }

  img.weather-icon {
    width: 30%;
  }

  .CurrentCity #current-city {
    font-size: 2rem;
  }
  .WeatherDetails.details {
    font-size: 1.2rem;
  }
  .forecast-text {
    font-size: 1rem;
    font-weight: 200;
  }
  body {
    background-size: 450%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  #geo-button {
    margin-bottom: 0.2rem;
    right: 10%;
  }
  .container {
    width: 90%;
  }

  img.weather-icon {
    width: 20%;
  }

  .CurrentCity #current-city {
    font-size: 3rem;
  }

  .details,
  #celsius-fahrenheit {
    font-size: 1.5rem;
  }

  .forecast-text {
    font-size: 1.5rem;
  }
  body {
    background-size: 250%;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  #geo-button {
    margin-bottom: 0.2rem;
    right: 10%;
  }
  .current-weather-section {
    flex-direction: row;
  }
  #current-city {
    font-size: 3.5rem;
  }
  body {
    background-size: 200%;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 40%;
  }
}
